import React, {useContext} from "react";
import {Link, useLocation, useNavigate, useParams} from "react-router-dom";
import {Controller, FormProvider, useForm} from "react-hook-form";
import {Button, FormControl, Grid, InputLabel, MenuItem, Select, TextField,} from "@mui/material";
import Title from "../title/Title";
import classes from "./AdminForm.module.css";
import UserContext from "../../contexts/UserContexts";
import PhoneField from "../phone-field/PhoneField";
import {useAdminPortalTranslation} from "../../api/translationApi";
import useInternalTranslation from "../../hooks/useInternalTranslation";
import useSnackBar from "../snack-bar/useSnackBar";
import {formInputStyles} from "../customer-form/lib";

const AdminForm = ({ method }) => {
  const { handleSubmit, control, register, reset } = useForm();
  const { businessId } = useParams();
  const { jwtToken } = useContext(UserContext);
  const location = useLocation();
  const { selectedItem } = location.state || {};
  const adminId = selectedItem?.id;
  const editForm = method === "PUT";
  const { t } = useInternalTranslation();
  const { show, CustomSnackBar } = useSnackBar();
  const navigate = useNavigate();
  const { adminPortalTranslation } = useAdminPortalTranslation();

  let url = `${process.env["REACT_APP_PORTAL_API_URL"]}/businesses/${businessId}/admin`;
  if (editForm) {
    url = `${process.env["REACT_APP_PORTAL_API_URL"]}/businesses/${businessId}/admin/${adminId}`;
  }

  const onSubmit = async (data) => {
    const adminData = {
      firstName: data.firstName,
      lastName: data.lastName,
      phoneNumber: data.phoneNumber,
      email: data.email,
      memberLanguage: data.memberLanguage,
    };

    const options = {
      method: method,
      headers: {
        Authorization: "Bearer " + jwtToken,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(adminData),
    };
    const response = await fetch(url, options);

    if (!response.ok) {
      if (response.status === 409) {
        show(t("Failed to Create Member. Member already exists with this email address"), "error")
      } else {
        const errorData = await response.json();
        console.error(`Error: ${response.status} ${response.statusText}`);
        show(errorData.message || "Failed to submit form")
      }
    } else {
      navigate(`/businesses/${businessId}`);
    }
  };

  return (
    <>
      <CustomSnackBar/>
      <FormProvider>
        <form
          noValidate
          method={method}
          className={classes.form}
          onSubmit={handleSubmit(onSubmit)}
        >
          <Grid container columnSpacing={8}>
            <Grid item xs={12} md={12}>
              {editForm ? <Title>Edit Admin</Title> : <Title>Add Admin</Title>}

              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <InputLabel htmlFor={"firstName"}>
                    {adminPortalTranslation["business_first_name"]}
                  </InputLabel>
                  <Controller
                    name={"firstName"}
                    control={control}
                    defaultValue={editForm ? selectedItem?.firstName : ""}
                    rules={{
                      required: t("First Name is required"),
                    }}
                    render={({ field, fieldState: { error } }) => (
                      <TextField
                        id={"firstName"}
                        {...register("firstName")}
                        fullWidth
                        required
                        sx={formInputStyles}
                        error={!!error}
                        helperText={error ? error.message : null}
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={6}>
                  <InputLabel htmlFor={"lastName"}>
                    {adminPortalTranslation["business_last_name"]}
                  </InputLabel>
                  <Controller
                    name={"lastName"}
                    control={control}
                    defaultValue={editForm ? selectedItem?.lastName : ""}
                    rules={{
                      required: t("Last Name is required"),
                    }}
                    render={({ field, fieldState: { error } }) => {
                      return (
                        <TextField
                          id={"lastName"}
                          {...register("lastName")}
                          fullWidth
                          required
                          sx={formInputStyles}
                          error={!!error}
                          helperText={error ? error.message : null}
                        />
                      );
                    }}
                  />
                </Grid>

                {editForm ? (
                  <Grid item xs={12}>
                    <InputLabel htmlFor={"username"}>{"Username"}</InputLabel>
                    <FormControl fullWidth>
                      <Controller
                        disabled={true}
                        name={"username"}
                        control={control}
                        defaultValue={editForm ? selectedItem?.userName : ""}
                        render={({ field, fieldState: { error } }) => {
                          return (
                            <TextField
                              id="tand"
                              {...register("username")}
                              fullWidth
                              required
                              sx={formInputStyles}
                              error={!!error}
                              helperText={error ? error.message : null}
                              disabled={editForm}
                              InputProps={{
                                inputProps: {
                                  readOnly: editForm,
                                  defaultValue: field?.value,
                                },
                              }}
                            />
                          );
                        }}
                      />
                    </FormControl>
                  </Grid>
                ) : (
                  <></>
                )}

                <Grid item xs={12}>
                  <PhoneField
                    gridSize={12}
                    control={control}
                    label={adminPortalTranslation["business_phone_number"]}
                    name="phoneNumber"
                    defaultValue={editForm ? selectedItem?.phoneNumber : ""}
                  />
                </Grid>

                <Grid item xs={12}>
                  <InputLabel htmlFor={"email"}>
                    {adminPortalTranslation["business_email_address"]}
                  </InputLabel>
                  <Controller
                    name={"email"}
                    control={control}
                    disabled={editForm}
                    defaultValue={editForm ? selectedItem?.email : ""}
                    rules={{
                      required: t("Email is required"),
                      validate: (value) => {
                        const emailPattern =
                          /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
                        return (
                          emailPattern.test(value) ||
                          t("Email has improper format")
                        );
                      },
                    }}
                    render={({ field, fieldState: { error } }) => (
                      <TextField
                        id={"email"}
                        type="email"
                        {...register("email")}
                        fullWidth
                        required
                        disabled={editForm}
                        sx={formInputStyles}
                        error={!!error}
                        helperText={error ? error.message : null}
                        InputProps={{
                          inputProps: {
                            readOnly: editForm,
                            defaultValue: field?.value,
                          },
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <InputLabel htmlFor={"memberLanguage"}>
                    {adminPortalTranslation.language}
                  </InputLabel>
                  <Controller
                    name={"memberLanguage"}
                    control={control}
                    defaultValue={selectedItem?.memberLanguage || "en_US"}
                    render={({ field, fieldState: { error } }) => (
                      <Select
                        type="memberLanguage"
                        id="memberLanguage"
                        {...register("memberLanguage")}
                        fullWidth
                        sx={formInputStyles}
                        defaultValue={selectedItem?.memberLanguage || "en_US"}
                      >
                        <MenuItem value={"en_US"}>English (US)</MenuItem>
                        <MenuItem value={"fr_CA"}>Français</MenuItem>
                      </Select>
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container spacing={4} alignItems="center">
            <Grid item xs={6}>
              <Button
                className={classes.button}
                component={Link}
                type="button"
                to={`/businesses/${businessId}`}
                variant="contained"
                size="large"
                fullWidth
                sx={{ my: 4, borderRadius: 0 }}
              >
                {adminPortalTranslation.button_cancel || "Cancel"}
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                className={classes.button}
                variant="contained"
                size="large"
                fullWidth
                sx={{ my: 4, borderRadius: 0 }}
                type="submit"
              >
                {adminPortalTranslation.button_save || "Save"}
              </Button>
            </Grid>
          </Grid>
        </form>
      </FormProvider>
    </>
  );
};

export default AdminForm;
