import {useContext, useEffect, useState} from "react";
import {UserContext} from "../contexts/UserContext";
import LanguageContext from "../contexts/LanguageContext";
import EnglishTranslations from "../assets/translationJSON/portalTranslationsEnglish.json";
import FrenchTranslations from "../assets/translationJSON/portalTranslationsFrench.json";

export const useAdminPortalTranslation = () => {
  const { data: userContextData } = useContext(UserContext);
  const { language } = useContext(LanguageContext);

  const [data, setData] = useState({});
  const [error, setError] = useState(undefined);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    function replaceVariables(target, variables) {
      // Helper function to replace variables in a string
      const replaceInString = (str, vars) => {
        return str.replace(/\{\{(\w+)\}\}/g, (_, key) =>
          vars[key] !== undefined ? vars[key] : `{{${key}}}`
        );
      };

      // Recursive function to traverse and replace values
      const traverse = (item, vars) => {
        if (typeof item === "string") {
          // If item is a string, replace any variables
          return replaceInString(item, vars);
        } else if (Array.isArray(item)) {
          // If item is an array, recurse on each element
          return item.map((el) => traverse(el, vars));
        } else if (typeof item === "object" && item !== null) {
          // If item is an object, recurse on each key-value pair
          return Object.keys(item).reduce((acc, key) => {
            acc[key] = traverse(item[key], vars);
            return acc;
          }, {});
        }
        // Return the item unchanged if it's not a string, array, or object
        return item;
      };

      return traverse(target, variables);
    }

    function getTranslationFromFile() {
      if (language === "fr_CA") return FrenchTranslations;
      else return EnglishTranslations;
    }

    function getLanguageJson() {
      setIsLoading(true);
      setError(undefined);

      const userData = {
        firstName: userContextData?.firstName,
        contactPhoneNumber: userContextData?.phoneNumber,
        emailAddress: userContextData?.email,
        acceptedFileTypes: ["csv"],
        maxImageHeight: 800,
        maxImageWidth: 800,
        supportPhoneNumber: "1-858-330-4872",
      };

      if (userContextData && userContextData.businessId && process.env["REACT_APP_SUPPORT_PHONE_" + userContextData.businessId]) {
        userData.supportPhoneNumber = process.env["REACT_APP_SUPPORT_PHONE_" + userContextData.businessId];
      }

      const translations = getTranslationFromFile();
      if (!!translations) {
        const replacedTranslations = replaceVariables(translations, userData);
        setData(replacedTranslations);
        setIsLoading(false);
      } else {
        setError("There was a problem fetching the language data.");
        setIsLoading(false);
      }
    }

    getLanguageJson();
  }, [language, userContextData]);

  return {
    adminPortalTranslation: data ? data : {},
    adminPortalTranslationIsLoading: isLoading,
    adminPortalTranslationError: error,
  };
};
