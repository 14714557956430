import dayjs from "dayjs";
import { Grid, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import DateRangePicker from "../DateRangePicker";
import UserContext from "../../contexts/UserContexts";
var utc = require("dayjs/plugin/utc");
var timezone = require("dayjs/plugin/timezone");
dayjs.extend(utc);
dayjs.extend(timezone);

export const formatDate = (dateString, langPref = "en_US") => {
  const d = dayjs(dateString).utc().tz("America/Toronto", false);

  if (d.year() > 2100) {
    return langPref === "en_US" ? "Recurring" : "Récurrent";
  } else {
    return d.format("MMM D, YYYY");
  }
};

export const formInputStyles = {
  bgcolor: "#F1F3F4",
  borderRadius: 1,
  "& .MuiFormHelperText-root": {
    color: "red",
    margin: 0,
    padding: 1,
    backgroundColor: "#fff",
  },
};

export function DisplayProduct(
  language,
  memberProduct,
  showDates = true,
  setNewEndDate = (date) => {}
) {
  const { userGroupPrefix } = useContext(UserContext);
  const [endDate, setEndDate] = useState(() => {
    let newDate = dayjs(memberProduct?.endDate).utc();
    newDate = newDate.set("hours", 0);
    newDate = newDate.add(6, "hours");

    return newDate;
  });

  useEffect(() => {
    setNewEndDate(endDate);
  }, [endDate]);

  const notAvailableString =
    language === "en_US" ? "Not Available" : "Non disponible";

  return (
    <div key={memberProduct?.id}>
      <Typography variant={"h6"} fontWeight={700}>
        {memberProduct?.productName ?? notAvailableString}
      </Typography>

      {showDates ? (
        <Grid container marginTop={"12px"}>
          <Grid item xs={6}>
            <strong>Start Date</strong>
          </Grid>
          <Grid item xs={6}>
            <strong>End Date</strong>
            {userGroupPrefix !== "BusinessAdmin" && (
              <DateRangePicker
                minDate={memberProduct?.startDate}
                endDate={endDate}
                setEndDate={setEndDate}
              />
            )}
          </Grid>
          <Grid item xs={6}>
            {formatDate(memberProduct?.startDate, language)}
          </Grid>
          <Grid item xs={6}>
            {formatDate(endDate, language)}
          </Grid>
        </Grid>
      ) : (
        <></>
      )}
    </div>
  );
}
