import {jwtDecode} from "jwt-decode";

const baseUrl = process.env.REACT_APP_PORTAL_API_URL;

const apiRequest = async (url, method, jwtToken, body = null) => {
    try {
        const options = {
            method,
            headers: {
                Authorization: "Bearer " + jwtToken,
                "Content-Type": "application/json",
            },
        };

        if (body) {
            options.body = JSON.stringify(body);
        }

        const response = await fetch(url, options);

        if (!response.ok) {
            const errorData = await response.json();
            throw new Error(
                errorData.message || `HTTP error! status: ${response.status}`
            );
        }

        const data = await response.json();
        return data;
    } catch (error) {
        console.error("API request error:", error);
        throw error;
    }
};

const makeApiRequest = async (url, method, jwtToken) => {
    try {
        const options = {
            method,
            headers: {
                Authorization: "Bearer " + jwtToken,
                "Content-Type": "application/json",
            },
        };

        const response = await fetch(url, options);

        if (!response.ok) {
            const errorData = await response.json();
            throw new Error(
                errorData.message || `HTTP error! status: ${response.status}`
            );
        }

        return await response.json();
    } catch (error) {
        console.error("API request error:", error);
        throw error;
    }
};

export const fetchLicenseData = async (jwtToken) => {
    return apiRequest(`${baseUrl}/members/license`, "GET", jwtToken);
};

export const fetchBusinessLicenseData = async (jwtToken, businessId) => {
    return makeApiRequest(`${baseUrl}/businesses/${businessId}/license`, "GET", jwtToken);
};

export const deleteLicense = async (jwtToken, productId) => {
    const decoded = jwtDecode(jwtToken);
    if (decoded) {
        return apiRequest(
            `${process.env.REACT_APP_API_URL}/retail/subscriptions/members/${decoded["cognito:username"]}/cancel`,
            "PUT",
            jwtToken,
            { productId },
        );
    } else {
        throw new Error("Invalid token");
    }
};
