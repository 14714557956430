import React, { useContext, useState } from "react";
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Snackbar,
  Typography,
} from "@mui/material";
import { UserContext } from "../../../../contexts/UserContext";
import classes from "../customer-details/CustomerDetails.module.css";
import { useInviteMember } from "../../../../pages/invite-member/useInviteMember.js";
import { useAdminPortalTranslation } from "../../../../api/translationApi";
import useDeleteLicense from "../../../../hooks/useDeleteLicense.js";
import dayjs from "dayjs";
import { signOut } from "aws-amplify/auth";
import { PATH_CONSTANTS } from "../../../../constants/pathConstants";
import { Navigate } from "react-router-dom";
import { LoadingButton } from "@mui/lab";

const ProductDetails = ({ profileData, translation }) => {
  const { data, loading } = useContext(UserContext);
  const jwtToken = localStorage.getItem("idToken");
  const [openDialog, setOpenDialog] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const { deleteError, handleDeleteLicense } = useDeleteLicense(jwtToken);
  const productDetails = profileData?.productDetails[0];
  const [loadingConfirm, setLoadingConfirm] = useState(false);

  const groupOwnerId =
    profileData.groupOwnerId !== "undefined"
      ? profileData.groupOwnerId
      : undefined;
  const [inviteToCancel, setInviteToCancel] = useState(undefined);

  const { show, InviteMemberPrompt, licenseDetails, CancelMember } =
    useInviteMember();

  const { adminPortalTranslation } = useAdminPortalTranslation();

  const startDate = dayjs(productDetails?.startDate).format("MMM D, YYYY");
  let endDate = dayjs(productDetails?.endDate).utc();
  endDate = endDate.set("hours", 0);
  endDate = endDate.add(6, "hours");
  endDate = endDate.format("MMM D, YYYY");

  if (loading) {
    return <div>Loading...</div>;
  }

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleConfirmCancel = async () => {
    setLoadingConfirm(true);
    await handleDeleteLicense(productDetails?.id);
    setLoadingConfirm(false);
    if (!deleteError) {
      handleCloseDialog();

      try {
        const language = localStorage.getItem("language");
        await signOut();
        localStorage.clear();

        if (language) {
          localStorage.setItem("language", language);
        }
        Navigate(PATH_CONSTANTS.LOGIN);
      } catch (error) {
        console.error("Error signing out: ", error);
      }
    }
  };

  const handleCancellationClick = () => {
    setOpenDialog(true);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
    setSnackbarMessage("");
  };

  const getStatusTranslation = (status) => {
    if (localStorage.getItem("language") == "en_US") return status;
    else if (status === "Invited") return "Invité";
    else return "Actif";
  };

  return (
    <>
      <InviteMemberPrompt />
      <CancelMember member={inviteToCancel} setMember={setInviteToCancel} />
      <Grid container spacing={4}>
        <Grid item xs={12} md={12}>
          <Typography variant="h4" sx={{ mt: 2, mb: 4 }} gutterBottom>
            {translation["subscription_details"]}
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs={12} md={12}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography
                variant="body1"
                fontWeight={700}
                sx={{ mb: 3 }}
                textTransform="uppercase"
              >
                {adminPortalTranslation["currentLicense"]}
              </Typography>
            </Grid>
            <Grid
              container
              sx={{ border: "1px solid #BBBBBB", borderRadius: 2, p: 3, pb: 2 }}
            >
              <Grid item xs={6}>
                <Typography
                  variant="body1"
                  fontWeight={700}
                  sx={{ mb: 1 }}
                  gutterBottom
                >
                  {productDetails?.productName ?? "No Product Name Found"}
                </Typography>
                <Typography variant="body1" sx={{ mb: 1 }} gutterBottom>
                  {productDetails?.description ?? ""}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography
                  variant="body1"
                  fontWeight={700}
                  sx={{ mb: 1, textAlign: "right" }}
                  gutterBottom
                >
                  {productDetails?.price ?? ""}
                </Typography>

                {!groupOwnerId && (
                  <Grid container spacing={1} marginTop={2}>
                    <Grid item xs={12} style={{ textAlign: "right" }}>
                      <Typography variant="body1">
                        {adminPortalTranslation["total"]}:{" "}
                        {licenseDetails?.numberOfLicenses || 0}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} style={{ textAlign: "right" }}>
                      <Typography variant="body1">
                        {adminPortalTranslation["available"]}:{" "}
                        {licenseDetails?.availableLicenses || 0}
                      </Typography>
                    </Grid>
                  </Grid>
                )}
              </Grid>
              <Grid container marginTop={2}>
                <Grid item xs={12}>
                  <Typography variant="subtitle2">
                    {adminPortalTranslation["valid"]}: {startDate} -{" "}
                    {dayjs(endDate).year() > 2100
                      ? adminPortalTranslation?.recurring
                      : endDate}
                  </Typography>
                </Grid>
              </Grid>
              {data?.groupOwnerId && data?.groupOwnerId !== "undefined" && (
                <Grid container marginTop={2}>
                  <Grid item xs={12}>
                    <Typography variant="subtitle2">
                      {adminPortalTranslation?.giftedBy}{" "}
                      {licenseDetails?.ownerName}
                    </Typography>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>

        {!groupOwnerId && licenseDetails?.numberOfLicenses > 1 && (
          <Grid container marginLeft={1} marginTop={3}>
            <Grid item xs={6} md={6}>
              <Typography
                variant="body1"
                fontWeight={700}
                sx={{ mb: 1 }}
                textTransform="uppercase"
              >
                {adminPortalTranslation?.giftLicenses}
              </Typography>
            </Grid>
            <Grid gap={3} item xs={12} md={6}>
              <Box display="flex" justifyContent="flex-end">
                <Button
                  onClick={show}
                  color="secondary"
                  variant="contained"
                  size="small"
                  disabled={licenseDetails?.availableLicenses === 0}
                  sx={{ borderRadius: 1, minWidth: "40%" }}
                >
                  + {adminPortalTranslation?.invite}
                </Button>
              </Box>
            </Grid>

            {!!licenseDetails &&
              licenseDetails?.groupMembers?.map((member, index) => (
                <Grid
                  key={"invite" + index}
                  style={{
                    alignItems: "center",
                    height: "83px",
                    padding: "8px",
                    borderRadius: "8px",
                    border: "1px solid #BBBBBB",
                  }}
                  marginTop={2}
                  container
                >
                  <Grid item xs={4}>
                    <Typography sx={{ flexShrink: 0 }}>
                      {member.firstName} {member.lastName}
                    </Typography>
                  </Grid>
                  <Grid item style={{ textAlign: "center" }} xs={4}>
                    <Typography
                      sx={{
                        fontWeight: 600,
                        pointerEvents: "none",
                      }}
                    >
                      {getStatusTranslation(member.status)}
                    </Typography>
                  </Grid>
                  <Grid item xs={4} style={{ textAlign: "right" }}>
                    <Button
                      variant="contained"
                      disableElevation
                      sx={{
                        backgroundColor: "#F1F3F4",
                        color: "black",
                        "&:hover": {
                          backgroundColor: "#969696",
                          color: "#fff",
                        },
                        fontWeight: 600,
                      }}
                      onClick={() => {
                        setInviteToCancel(member);
                      }}
                    >
                      {adminPortalTranslation?.button_cancel}
                    </Button>
                  </Grid>
                </Grid>
              ))}
          </Grid>
        )}

        {!groupOwnerId && data?.canCancel && (
          <Grid container spacing={1} marginTop={3}>
            <Grid item xs={12} sm={5}>
              <Button
                onClick={handleCancellationClick}
                color="error"
                variant="contained"
                size="large"
                fullWidth
              >
                {translation.label_cancel_subscription}
              </Button>
            </Grid>
          </Grid>
        )}
      </Grid>
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>
          {adminPortalTranslation?.cancelSubscriptionConfimation}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {adminPortalTranslation?.cancelSubscriptionMessage}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseDialog}
            className={classes.greyButton}
            variant="contained"
          >
            {adminPortalTranslation?.cancel_button}
          </Button>
          <LoadingButton
            onClick={handleConfirmCancel}
            color="secondary"
            variant="contained"
            loading={loadingConfirm}
          >
            {adminPortalTranslation?.confirm_button}
          </LoadingButton>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity="error"
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default ProductDetails;
