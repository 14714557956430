import {React, useContext, useEffect, useState} from "react";
import {ThemeProvider} from "@mui/material/styles";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import {
    AppBar,
    Avatar,
    Button,
    Container,
    Menu,
    MenuItem,
    Select,
    Tooltip,
} from "@mui/material";
import CompanyLogo from "../../assets/KnowScam_white.svg";
import CompanyLogoSmall from "../../assets/KnowScam_white-small.png";
import {Link, NavLink, useNavigate} from "react-router-dom";
import {PATH_CONSTANTS} from "../../constants/pathConstants";
import {withAuthenticator} from "@aws-amplify/ui-react";
import "./Navigation.modules.css";
import UserContext from "../../contexts/UserContexts";
import {UserContext as PortalData} from "../../contexts/UserContext";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import useAvatar from "../avatar/useAvatar";
import useCobrand from "../co-branding/useCobrand";
import {useAdminPortalTranslation} from "../../api/translationApi";

function showLogo(customLogo) {
    if (customLogo) {
        return <img src={customLogo} alt="Scamnetic" width="auto" height="65px"/>;
    } else {
        return <img src={CompanyLogo} alt="Scamnetic" width="auto" height="35px"/>;
    }
}

const Navigation = ({signOut, user}) => {
    const [anchorElUser, setAnchorElUser] = useState(null);
    const [userInitials, setUserInitials] = useState("");
    const {userGroupPrefix} = useContext(UserContext);
    const {avatar, reloadAvatar} = useAvatar(user.username);
    const {data, loading} = useContext(PortalData);
    const {customLogo, theme, themeStyles, logoLoaded} = useCobrand();
    const navigate = useNavigate();
    const businessID = data?.businessId;

    const {
        adminPortalTranslation,
        adminPortalTranslationIsLoading,
        adminPortalTranslationError,
    } = useAdminPortalTranslation();

    // const styles = customLogo ? ScamneticBrand : CustomBrand;
    useEffect(() => {
        reloadAvatar(user.username);
    }, [user.username, reloadAvatar]);

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    useEffect(() => {
        const firstInitial = data?.firstName?.charAt(0);
        const secondInitial = data?.lastName?.charAt(0);
        const avatarInitials = firstInitial + secondInitial;
        setUserInitials(avatarInitials);
    }, [data]);

    const signOutHandler = async () => {
        try {
            const language = localStorage.getItem("language"); // Store the language key
            await signOut();
            localStorage.clear();
            if (language) {
                localStorage.setItem("language", language); // Restore the language key
            }
            window.location.href = PATH_CONSTANTS.LOGIN;
        } catch (error) {
            console.error("Error signing out: ", error);
        }
    };

    const isCustomer = userGroupPrefix === undefined;
    const isAdmin = userGroupPrefix === "Admin";
    const isBusAdmin = userGroupPrefix === "BusinessAdmin";

    if (adminPortalTranslationIsLoading) {
        return <div>Loading...</div>;
    }

    if (adminPortalTranslationError) {
        return <div>Error with Portal Admin Translations...</div>;
    }

    return (
        <ThemeProvider theme={theme}>
            <AppBar position="static" sx={{boxShadow: "none"}}>
                <Container className={themeStyles} maxWidth="xl">
                    <Toolbar disableGutters style={{maxHeight: "80px"}}>
                        <Box sx={{display: {xs: "none", md: "flex"}, mr: 1}}>
                            <Link to={PATH_CONSTANTS.HOME}>
                                {logoLoaded && showLogo(customLogo)}
                            </Link>
                        </Box>

                        <Box sx={{display: {xs: "flex", md: "none"}, mr: 1}}>
                            <Link to={PATH_CONSTANTS.HOME}>
                                <img
                                    src={CompanyLogoSmall}
                                    alt="Scamnetic"
                                    width="35px"
                                    height="40px"
                                />
                            </Link>
                        </Box>

                        <Box sx={{flexGrow: 1, display: "flex"}} justifyContent="flex-end">
                        {!isCustomer && (
                                <>
                                    {(isAdmin || isBusAdmin) && (
                                        <Button
                                            id="basic-button"
                                            to={PATH_CONSTANTS.BUSINESS_LIST}
                                            component={Link}
                                            sx={{
                                                color: "white",
                                                textTransform: "none",
                                            }}
                                        >
                                            Business Listings
                                        </Button>
                                    )}

                                  {isAdmin && (
                                      <Button
                                          id="basic-button"
                                          to={PATH_CONSTANTS.PRODUCTS}
                                          component={Link}
                                          sx={{
                                              color: "white",
                                              textTransform: "none",
                                          }}
                                      >
                                          Products
                                      </Button>
                                  )}

                    <Button
                        id="basic-button"
                        to={PATH_CONSTANTS.REPORTS}
                        component={Link}
                        sx={{
                            color: "white",
                            textTransform: "none",
                        }}
                    >
                        {adminPortalTranslation.reports_header}
                    </Button>
                  {isAdmin && (
                      <>
                          <Button
                              id="basic-button"
                              aria-controls={open ? "basic-menu" : undefined}
                              aria-haspopup="true"
                              aria-expanded={open ? "true" : undefined}
                              onClick={handleClick}
                              sx={{
                                  color: "white",
                                  textTransform: "none",
                              }}
                              endIcon={<ArrowDropDownIcon/>}
                          >
                              Settings
                          </Button>
                          <Menu
                              id="settings"
                              anchorEl={anchorEl}
                              open={open}
                              onClose={handleClose}
                              MenuListProps={{
                                  "aria-labelledby": "basic-button",
                              }}
                          >
                              <MenuItem component={Link} to={PATH_CONSTANTS.NEXT_STEPS}>
                                  Next Steps
                              </MenuItem>
                              <MenuItem component={Link} to={PATH_CONSTANTS.WHITELIST}>
                                  Domain Whitelist
                              </MenuItem>
                          </Menu>
                      </>
                  )}
                                </>
                            )}
                        </Box>
                        <Box sx={{flexGrow: 0}}>
                            <Tooltip title={adminPortalTranslation.hover_over_profile}>
                                <IconButton onClick={handleOpenUserMenu} sx={{p: 0}}>
                                    {loading ? (
                                        <Avatar/>
                                    ) : (
                                        <Avatar
                                            sx={{
                                                width: 32,
                                                height: 32,
                                                padding: 3,
                                                color: "#020D0F",
                                                fontWeight: 700,
                                            }}
                                        >
                                            {avatar ? (
                                                <img
                                                    src={avatar}
                                                    alt="User Avatar"
                                                    style={{maxWidth: "50px"}}
                                                />
                                            ) : (
                                                userInitials
                                            )}
                                        </Avatar>
                                    )}
                                </IconButton>
                            </Tooltip>
                            <Menu
                                sx={{mt: "45px"}}
                                id="menu-appbar"
                                anchorEl={anchorElUser}
                                anchorOrigin={{
                                    vertical: "top",
                                    horizontal: "right",
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: "top",
                                    horizontal: "right",
                                }}
                                open={Boolean(anchorElUser)}
                                onClose={handleCloseUserMenu}
                            >
                                <MenuItem
                                    className="menuItem"
                                    component={Link}
                                    to={PATH_CONSTANTS.PROFILE}
                                    onClick={handleCloseUserMenu}
                                >
                                    <Typography textAlign="center">
                                        {adminPortalTranslation.menu_item_1}
                                    </Typography>
                                </MenuItem>
                                <MenuItem component={"a"} onClick={signOutHandler}>
                                    <Typography textAlign="center">
                                        {adminPortalTranslation.menu_item_2}
                                    </Typography>
                                </MenuItem>
                            </Menu>
                        </Box>
                    </Toolbar>
                </Container>
            </AppBar>
            <Box className="scamPhone" bgcolor="#f2820a" padding={1}>
                <Typography
                    variant="body1"
                    color="white"
                    textAlign="center"
                    fontWeight={500}
                >
                    {adminPortalTranslation.contact_message}
                </Typography>
            </Box>
        </ThemeProvider>
    );
};

export default withAuthenticator(Navigation);
