import React, {useContext, useEffect, useState} from "react";
import {Link, useLocation, useParams} from "react-router-dom";
import DisplayList from "../../components/display-list/DisplayList";
import {PATH_CONSTANTS} from "../../constants/pathConstants";
import {fetchBusinessInfo, fetchMembersList} from "../../utils/api";
import {useAdminPortalTranslation} from "../../api/translationApi";
import LanguageContext from "../../contexts/LanguageContext";
import Box from "@mui/material/Box";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
  TextField
} from "@mui/material";
import classes from "../../components/list-header/ListHeader.module.css";
import HeaderTitle from "../../components/header-title";
import {Controller, FormProvider, useForm} from "react-hook-form";
import {Flex} from "@aws-amplify/ui-react";
import useInternalTranslation from "../../hooks/useInternalTranslation";
import {fetchAuthSession} from 'aws-amplify/auth';
import UserContext from "../../contexts/UserContexts";

const customerColumns = [
  { dataField: (item) => item.firstName },
  { dataField: (item) => item.lastName },
  { dataField: (item) => item.status },
  { dataField: (item) => item.memberType },
  { dataField: (item) => item.phoneNumber },
  { dataField: (item) => item.email },
];

const fetchIdToken = async () => {
  try {
    const session = await fetchAuthSession();
    const idToken = session.tokens.idToken;
    return idToken;
  } catch (error) {
    console.error('Error fetching ID Token:', error);
  }
};


const CustomerList = ({ editRoute }) => {
  const { businessId } = useParams();
  const baseURL = process.env["REACT_APP_PORTAL_API_URL"];
  const [customers, setCustomers] = useState([]);
  const [nextToken, setNextToken] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [idToken, setIdToken] = useState(null);
  const { t } = useInternalTranslation();
  const [error, setError] = useState(false);
  const location = useLocation();
  const [businessName, setBusinessName] = useState("");
  const {userGroupPrefix} = useContext(UserContext);
  const isAdmin = userGroupPrefix === "Admin";
  const isBusAdmin = userGroupPrefix === "BusinessAdmin";
  const [selectedItem] = useState(() => {
    // Try to get the selectedItem from location.state first
    const itemFromState = location.state?.selectedItem;
    if (itemFromState) {
      setBusinessName(itemFromState?.businessName);
      return itemFromState;
    }
    const storedItem = localStorage.getItem("selectedItem");
    return storedItem ? JSON.parse(storedItem) : null;
  });
  const type = "ME";

  const {
    adminPortalTranslation,
    adminPortalTranslationIsLoading,
    adminPortalTranslationError,
  } = useAdminPortalTranslation();

  const { language } = useContext(LanguageContext);

  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm();
  const [open, setOpen] = useState(false);

  const memberTypeString =
    language === "en_US" ? "Member Type" : "Détails commerciaux";

  const headers = [
    adminPortalTranslation.business_first_name,
    adminPortalTranslation.business_last_name,
    adminPortalTranslation.subscription_active,
    memberTypeString,
    adminPortalTranslation.business_phone_number,
    adminPortalTranslation.business_email_address,
    adminPortalTranslation.product_header_action,
  ];

  const onSubmit = async ({ email }) => {
    if (email) {
      try {
        const results = await searchCustomer(email);
        if (results.length > 0) {
          setCustomers(results);
        } else {
          setOpen(true);
        }
      } catch (error) {
        console.error("Failed to search customer:", error);
      }
    }
  };

  useEffect(() => {
    fetchIdToken().then(idToken => {
      setIdToken(idToken);
      if (!businessName) {
        fetchBusinessInfo(businessId, idToken).then(data => {
          setBusinessName(data?.data?.name);
        });
      }
    })


    //   localStorage.setItem("selectedItem", JSON.stringify(selectedItem));
  }, []);

  useEffect(() => {
    if (idToken) {
      fetchCustomers();
    }
  }, [idToken]);

  const fetchCustomers = async () => {
    try {
      const memberListResponse = await fetchMembersList(
        businessId,
        idToken,
        nextToken,
        type
      );

      if (memberListResponse?.statusCode < 299) {
        setCustomers([...customers, ...memberListResponse.data]);
        setNextToken(memberListResponse.nextToken);
        setError(null);
      }
    } catch (err) {
      setError(err.message);
      console.log(error);
      setCustomers([]);
    } finally {
      setIsLoading(false);
    }
  };

  const hasMore = () => {
    return !!nextToken;
  };

  const handleLoadMoreClick = async (e) => {
    e.preventDefault();
    await fetchCustomers(false);
  };

  if (
    adminPortalTranslationIsLoading ||
    !adminPortalTranslation ||
    headers.some((header) => !header)
  ) {
    return <div>Loading...</div>;
  }

  if (adminPortalTranslationError) {
    return <div>Error: {adminPortalTranslationError.message}</div>;
  }

  const clearSearchResult = async () => {
    window.location.reload();
  };

  const handleClose = () => {
    setOpen(false);
  };

  const searchCustomer = async (email) => {
    const url = `${baseURL}/businesses/${businessId}/members/${encodeURIComponent(email)}`;

    try {
        const response = await fetch(
            url,
            {
              method: "GET",
              headers: {
                Authorization: "Bearer " + idToken,
              },
            }
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        return data.data;
    } catch (error) {
      console.error("Failed to fetch business info:", error);
      throw error;
    }
  };


  return (
    <>
      <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          marginBottom="16px"
      >
        <Box>
          <HeaderTitle>{(isBusAdmin || isAdmin)
              ? `${businessName} - ${adminPortalTranslation.customers}`
              : adminPortalTranslation.customers}</HeaderTitle>
        </Box>
      </Box>

      <Box style={{ display: "flex", "justifyContent": "space-between", marginBottom: "24px" }}>
        <FormProvider>
          <form onSubmit={handleSubmit(onSubmit)} noValidate>
            <Flex>
              <Controller
                  name="email"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: t("Email is required"),
                  }}
                  render={({ field }) => (
                      <TextField
                          size="small"
                          {...field}
                          fullWidth
                          variant="outlined"
                          type="text"
                          placeholder={adminPortalTranslation.search_by_email}
                          sx={{ backgroundColor: "#F1F3F4" }}
                          error={!!errors.email}
                          helperText={errors.email ? errors.email.message : ""}
                      />
                  )}
              />
              <Stack spacing={2} direction="row">
                <Button
                    type="submit"
                    variant="contained"
                    size="small"
                    className={classes.button}
                    sx={{ borderRadius: 0 }}
                >
                  {adminPortalTranslation.button_search}
                </Button>
                <Button
                    variant="outline"
                    size="small"
                    className={classes.buttonOutline}
                    sx={{ borderRadius: 0 }}
                    onClick={clearSearchResult}
                >
                  {adminPortalTranslation.button_clear}
                </Button>
              </Stack>
            </Flex>
          </form>
        </FormProvider>

        <Box style={{ display: "flex", gap: "1rem" }}>
          <Button
              component={Link}
              to={!!editRoute ? `${editRoute}/add` : "add"}
              variant="contained"
              size="medium"
              className={classes.button}
              sx={{ borderRadius: 0 }}
          >
            {adminPortalTranslation?.button_add || "Add"}
          </Button>

        </Box>

        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>{t("No Customer Found")}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {t("No customer was found with the provided email address")}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              OK
            </Button>
          </DialogActions>
        </Dialog>
      </Box>

      <DisplayList
          type="Customer"
          listData={customers}
          headers={headers}
          columns={customerColumns}
          isLoading={isLoading}
          error={error}
          path={PATH_CONSTANTS.CUSTOMER_LIST}
          hasMore={hasMore}
          handleLoadMoreClick={handleLoadMoreClick}
          showCustomersLink={false}
          editRoute={editRoute}
      />
    </>
  );
};

export default CustomerList;
