import {
  Alert,
  Box,
  Button,
  IconButton,
  MenuItem,
  Select,
  Snackbar,
  TextField,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import Title from "../../components/title";
import { Link } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import classes from "./NextSteps.module.css";
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  CircularProgress,
  Tooltip,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import UserContext from "../../contexts/UserContexts";
import CloseIcon from "@mui/icons-material/Close";
import { deleteNextStep, fetchNextSteps } from "../../utils/api";
import { useAdminPortalTranslation } from "../../api/translationApi";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const headers = ["Channel", "Key", "Description"];

const NextStepsTable = () => {
  const [recommendationsAndFactors, setRecommendationsAndFactors] = useState(
    []
  );
  const { jwtToken } = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);

  const [filterBy, setFilterBy] = useState(" ");

  const [snackbarProps, setSnackbarProps] = useState({
    message: "",
    severity: "success",
  });

  const [open, setOpen] = useState(false);

  const { adminPortalTranslation } = useAdminPortalTranslation();

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  useEffect(() => {
    const getData = async () => {
      if (!jwtToken) return;

      setIsLoading(true);

      let loadingSteps = true;
      let nextSteps = [];
      let nextToken = undefined;

      while (loadingSteps) {
        const data = await fetchNextSteps(jwtToken, nextToken);

        nextSteps = [...nextSteps, ...data?.data?.items];

        if (data?.data?.nextToken && data?.data?.nextToken !== undefined) {
          loadingSteps = true;
          nextToken = data.data.nextToken;
        } else {
          loadingSteps = false;
        }
      }

      setRecommendationsAndFactors(nextSteps);
      setIsLoading(false);
    };
    getData();
  }, [jwtToken]);

  function removeItemByIndex(index) {
    const tempItems = [...recommendationsAndFactors];

    tempItems.splice(index, 1);

    setRecommendationsAndFactors(tempItems);
  }

  async function handleDelete(id, index) {
    const deleteStatus = await deleteNextStep(id, jwtToken);

    if (deleteStatus === 200 || deleteStatus === 202) {
      removeItemByIndex(index);
      setSnackbarProps({
        message: "Successfully deleted!",
        severity: "success",
      });
    } else {
      setSnackbarProps({
        message: "There was a problem deleting your item. Please try again.",
        severity: "error",
      });
    }
    setOpen(true);
  }

  function getTypeByChar(index, char) {
    if (char === "E") return "Email";
    else return "Text";
  }

  function getDescriptionInLanguage(index, language) {
    const item = recommendationsAndFactors[index];
    for (let i = 0; i < item.description.length; i++) {
      if (item.description[i].lang === language)
        return item.description[i].text;
    }

    return `Description for language ${language} not found.`;
  }

  return (
    <>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert
          severity={snackbarProps.severity}
          variant="filled"
          sx={{ width: "100%" }}
        >
          <>
            {snackbarProps.message}
            &emsp;
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={handleClose}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </>
        </Alert>
      </Snackbar>
      <Box
        sx={{
          display: "flex",
          justifyContent: "right",
          marginBottom: "25px",
        }}
      >
        <Button
          to="/settings/nextsteps/add"
          component={Link}
          variant="contained"
          size="large"
          className={classes.button}
          sx={{ borderRadius: 0 }}
        >
          Add
        </Button>
      </Box>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        marginBottom="16px"
      >
        <Box>
          <Title>
            {adminPortalTranslation?.recommendationsContributingFactors ||
              "Recommendations & Contributing Factors"}
          </Title>
        </Box>
      </Box>
      <Box>
        {isLoading ? (
          <>
            <Table size="large">
              <TableHead>
                <StyledTableRow>
                  {headers.map((header) => (
                    <StyledTableCell key={header} align="left">
                      {header}
                    </StyledTableCell>
                  ))}
                  <StyledTableCell align="right">Action</StyledTableCell>
                </StyledTableRow>
              </TableHead>
            </Table>
            <Box
              display="flex"
              alignContent="center"
              justifyContent="center"
              sx={{ my: 10 }}
            >
              <CircularProgress />
            </Box>
          </>
        ) : (
          <TableContainer>
            <Table>
              <TableHead>
                <StyledTableRow>
                  {headers.map((header) => (
                    <StyledTableCell key={header} align="left">
                      {header}
                    </StyledTableCell>
                  ))}
                  <StyledTableCell align="right">Action</StyledTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {!recommendationsAndFactors ? (
                  <StyledTableRow>
                    <StyledTableCell colSpan={6}>
                      No recommendations or factors found. Please add a
                      recommendation or factor.
                    </StyledTableCell>
                  </StyledTableRow>
                ) : (
                  recommendationsAndFactors.map((data, index) => (
                    <StyledTableRow key={data?.id}>
                      <StyledTableCell>
                        {/* {data?.channel?.charAt(0).toUpperCase() +
                          data?.channel.slice(1)} */}
                        {data?.channel && getTypeByChar(index, data?.channel)}
                      </StyledTableCell>
                      <StyledTableCell>{data?.stepValue}</StyledTableCell>
                      <StyledTableCell>
                        {getDescriptionInLanguage(index, "en_US")}
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                          }}
                        >
                          <Tooltip title="Edit">
                            <Link
                              className={classes.icon}
                              to={`/settings/nextsteps/${data?.id}`}
                              component={Link}
                              style={{ padding: "4px" }}
                            >
                              <EditIcon />
                            </Link>
                          </Tooltip>
                          <Tooltip title="Delete">
                            <Link
                              className={classes.icon}
                              onClick={() => {
                                handleDelete(data?.id, index);
                              }}
                              style={{ padding: "4px" }}
                            >
                              <DeleteIcon />
                            </Link>
                          </Tooltip>
                        </div>
                      </StyledTableCell>
                    </StyledTableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Box>
    </>
  );
};

export default NextStepsTable;
