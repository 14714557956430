// import react and react router dom
import React, { useContext, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";

// import material ui components
import { Box, Typography } from "@mui/material";

// import components
import IndustryNewsCard from "./IndustryNewsCard";

// import API
import { fetchContentful } from "../../../utils/api";

// import contexts
import LanguageContext from "../../../contexts/LanguageContext";

import styles from "./IndustryNews.module.css";

const IndustryNewsContainer = () => {
  const [industryNews, setindustryNews] = useState(null);

  const { language } = useContext(LanguageContext);

  const query = useMemo(() => {
    return `
  {
    industryNews(id: "Jy4zb3dRkvhps77MGokwX", locale: ${
      language === "en_US" ? '"en-US"' : '"fr-CA"'
    }) {
      backgroundImage {
        url
      }
      title
      bubble
      heading
      subHeading
      description
      linkText
      link
      cardsCollection {
        items {
          icon {
            url
            title
          }
          heading
          description
          buttonText
          buttonLink
        }
      }
    }
  }
  `;
  }, [language]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await fetchContentful(query);
        setindustryNews(data.industryNews);
      } catch (error) {
        console.log("Failed", { error });
      }
    };

    fetchData();
  }, [query]);

  if (!industryNews) {
    return "Loading...";
  }
  return (
    <Box className={styles.industryNewsContainer}>
      <Box
        className={styles.industryNewsContent}
        style={{ backgroundImage: `url(${industryNews.backgroundImage.url})` }}
      >
        <Box className={styles.industryNewBubble}>
          <Typography>{industryNews.bubble}</Typography>
        </Box>
        <Box className={styles.industryHeading}>
          <Box
            className={styles.industryNewsHeading}
            sx={{ fontWeight: "800", marginTop: "32px" }}
          >
            {industryNews.heading}
          </Box>
          <Box className={styles.industryNewsHeading}>
            {industryNews.subHeading}
          </Box>
          <Box
            sx={{
              fontSize: "16px",
              fontWeight: "500",
              marginTop: "25px",
              color: "#002129",
            }}
          >
            {industryNews.description}
          </Box>
          <Link className={styles.industryNewslink} to={industryNews.link}>
            {industryNews.linkText}
          </Link>
        </Box>
        <Box className={styles.industryNewsCardsContainer}>
          {industryNews.cardsCollection.items.map((item, index) => (
            <IndustryNewsCard key={index} card={item} />
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default IndustryNewsContainer;
