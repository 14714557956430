import React, { useState } from "react";
import IconButton from "@mui/material/IconButton";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import DatePicker from "react-multi-date-picker";
import "react-multi-date-picker/styles/colors/teal.css";
import { Button } from "@mui/material";
import { useParams } from "react-router-dom";

const DateRangePicker = ({ minDate, endDate, setEndDate }) => {
  const { businessId, customerId } = useParams();

  return (
    <div style={{ position: "relative", display: "inline-block" }}>
      {/* Invisible text box overlaid on IconButton */}
      <DatePicker
        single
        value={new Date(endDate)}
        onChange={setEndDate}
        portal
        minDate={minDate}
        style={{
          zIndex: 1300,
          position: "absolute",
          top: "-1px",
          left: "4px",
          opacity: 0,
          width: "80%",
          height: "80%",
          pointerEvents: "all",
        }}
        // plugins={[<ConfirmButton position="bottom" />]}
      />
      {/* IconButton */}
      <IconButton aria-label="Toggle date range picker">
        <CalendarMonthIcon />
      </IconButton>
    </div>
  );
};

export default DateRangePicker;
