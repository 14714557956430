export const PATH_CONSTANTS = {
  HOME: "/",
  LOGIN: "/?landing=signin",
  PROFILE: "profile",
  CUSTOMER_LIST: "customers",
  BUSINESS_LIST: "businesses",
  PRODUCTS: "products",
  REPORTS: "reports",
  WHITELIST: "whitelist",
  NEXT_STEPS: "settings/nextsteps",
};
