import {
  Alert,
  Box,
  Button,
  FormControlLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Snackbar,
  TextField,
  CircularProgress,
} from "@mui/material";
import Title from "../../components/title";
import { useNavigate, useParams } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import UserContext from "../../contexts/UserContexts";
import {
  createNextStep,
  fetchNextStepById,
  updateNextStep,
} from "../../utils/api";
import useSnackBar from "../../components/snack-bar/useSnackBar";
import { useAdminPortalTranslation } from "../../api/translationApi";

const NextStepsForm = () => {
  const { id } = useParams();
  const [addForm, setAddForm] = useState(id === undefined);
  const { show, CustomSnackBar } = useSnackBar();

  const [formData, setFormData] = useState({
    channel: "",
    stepValue: "",
    description_en: "",
    description_fr: "",
    type: undefined,
  });

  const { jwtToken } = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});

  const navigate = useNavigate();

  const { adminPortalTranslation } = useAdminPortalTranslation();

  function getDescriptionInLanguage(item, language) {
    for (let i = 0; i < item.description.length; i++) {
      if (item.description[i].lang === language)
        return item.description[i].text;
    }
    return `Description for language ${language} not found.`;
  }

  useEffect(() => {
    if (!addForm) setFormData({ ...formData, id: id });

    const getItemById = async () => {
      if (!jwtToken) return;

      setIsLoading(true);
      const data = await fetchNextStepById(id, jwtToken);
      let tempData = { ...data };

      tempData.description_en = getDescriptionInLanguage(tempData, "en_US");
      tempData.description_fr = getDescriptionInLanguage(tempData, "fr_CA");
      delete tempData.description;

      setFormData(tempData);
      setIsLoading(false);
    };

    if (!addForm) getItemById();
  }, [jwtToken]);

  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
    setErrors((prevErrors) => ({ ...prevErrors, [event.target.name]: "" }));
  };

  const validateForm = () => {
    let formErrors = {};
    if (!formData.channel)
      formErrors.channel = "Communication type is required";
    if (!formData.stepValue) formErrors.stepValue = "Key value is required";
    if (!formData.description_en)
      formErrors.description_en = "English description is required";
    if (!formData.description_fr)
      formErrors.description_fr = "French description is required";
    if (!formData.type) formErrors.type = "Type is required";

    setErrors(formErrors);
    return Object.keys(formErrors).length === 0;
  };

  async function handleSubmit() {
    if (!validateForm()) return;

    let response;
    if (addForm) response = await createNextStep(formData, jwtToken);
    else response = await updateNextStep(formData, jwtToken);

    if (response?.status !== 200) {
      show(
        response?.message ||
          "There was a problem saving your recommendation or factor!",
        "error"
      );
    } else if (addForm) {
      const data = response.data;

      setAddForm(false);
      setFormData({ ...formData, id: data.id });

      show("Successfully created recommendation or factor!", "success");
    } else {
      show("Successfully updated recommendation or factor!", "success");
    }
  }

  function navigateToCancel() {
    navigate("/settings/nextsteps");
  }

  return (
    <>
      <CustomSnackBar />
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box sx={{ marginTop: "25px" }}>
          <Title>
            {adminPortalTranslation?.recommendationsContributingFactors ||
              "Recommendations & Contributing Factors"}
          </Title>
        </Box>
      </Box>
      <Box>
        {isLoading ? (
          <Box
            display="flex"
            alignContent="center"
            justifyContent="center"
            sx={{ my: 10 }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "50px",
              width: "100%",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "25px",
                width: "100%",
              }}
            >
              <div>
                <h3>{adminPortalTranslation.selectCommunicationType}</h3>
                <RadioGroup
                  name="channel"
                  value={formData?.channel}
                  onChange={handleChange}
                  disabled={!addForm}
                >
                  <FormControlLabel
                    value="E"
                    control={
                      <Radio
                        disabled={!addForm}
                        sx={{ "&, &.Mui-checked": { color: "black" } }}
                      />
                    }
                    label={adminPortalTranslation.email}
                  />
                  <FormControlLabel
                    value="T"
                    control={
                      <Radio
                        disabled={!addForm}
                        sx={{ "&, &.Mui-checked": { color: "black" } }}
                      />
                    }
                    label={adminPortalTranslation.text}
                  />
                </RadioGroup>
                {errors.channel && (
                  <p style={{ color: "red" }}>{errors.channel}</p>
                )}
              </div>
              <div>
                <h3>{adminPortalTranslation.keyValue}</h3>
                <TextField
                  id="stepValue"
                  name="stepValue"
                  value={formData?.stepValue}
                  onChange={handleChange}
                  placeholder="Add code"
                  sx={{
                    width: "20%",
                    bgcolor: "#F1F3F4",
                    borderRadius: 2,
                  }}
                  helperText={errors.stepValue}
                  error={!!errors.stepValue}
                  disabled={!addForm}
                />
              </div>
            </Box>
            <Box
              sx={{ display: "flex", flexDirection: "column", width: "100%" }}
            >
              <h3>{adminPortalTranslation.recommendationOrFactor}</h3>
              <Select
                sx={{
                  width: "35%",
                  bgcolor: "#F1F3F4",
                  borderRadius: 2,
                }}
                value={formData?.type || ""}
                onChange={handleChange}
                name="type"
                disabled={!addForm}
              >
                <MenuItem disabled value={undefined}>
                  <span style={{ color: "#999a9b" }}>
                    {adminPortalTranslation.select}
                  </span>
                </MenuItem>
                <MenuItem value="RECO">
                  {adminPortalTranslation.recommendation}
                </MenuItem>
                <MenuItem value="FACT">
                  {adminPortalTranslation.factor}
                </MenuItem>
              </Select>
              {errors.type && <p style={{ color: "red" }}>{errors.type}</p>}
            </Box>
          </div>
        )}
      </Box>
      <div
        style={{
          marginTop: "25px",
          display: "flex",
          flexDirection: "column",
          gap: "25px",
        }}
      >
        <div>
          <h3>{adminPortalTranslation.descriptionEnglish}</h3>
          <TextField
            fullWidth
            id="description_en"
            name="description_en"
            value={formData?.description_en || ""}
            onChange={handleChange}
            placeholder="Enter name of recommendation or CF"
            sx={{ bgcolor: "#F1F3F4", borderRadius: 2 }}
            helperText={errors.description_en}
            error={!!errors.description_en}
          />
          <h3>{adminPortalTranslation.descriptionFrench}</h3>
          <TextField
            fullWidth
            id="description_fr"
            name="description_fr"
            value={formData?.description_fr || ""}
            onChange={handleChange}
            placeholder="Enter name of recommendation or CF"
            sx={{ bgcolor: "#F1F3F4", borderRadius: 2 }}
            helperText={errors.description_fr}
            error={!!errors.description_fr}
          />
        </div>
        <div style={{ display: "flex", gap: 10 }}>
          <Button
            onClick={() => navigateToCancel()}
            variant="contained"
            size="large"
            color="tertiary"
            sx={{ borderRadius: 0, width: "25%" }}
          >
            Cancel
          </Button>
          <Button
            onClick={() => handleSubmit()}
            variant="contained"
            size="large"
            color="secondary"
            sx={{ borderRadius: 0, width: "25%" }}
          >
            Save
          </Button>
        </div>
      </div>
    </>
  );
};

export default NextStepsForm;
